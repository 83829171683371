<template>
  <v-card class="pa-2">
    <v-card-title>
      <h2>{{ hubDetailsData && hubDetailsData.name ? `${hubDetailsData.name}` : '' }} </h2>
    </v-card-title>
    <v-divider class="my-2"></v-divider>
    <v-row v-if="!loading" wrap no-gutters class="pb-2">
      <v-col
        cols="12"
        class="fill-height"
      >
        <v-tabs
          v-model="tab"
        >
          <v-tabs-slider color="accent"></v-tabs-slider>

          <v-tab
            :key="0"
          >
            {{ $lang.labels.overview }}
          </v-tab>
          <v-tab
            v-if="validationShow"
            :key="1"
          >
            {{ $lang.labels.validation }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pb-6 fill-height">
          <v-tab-item
            :key="0"
            class="fill-height"
          >
            <v-card flat class="pa-2 fill-height">
              <h2>{{ $lang.labels.descriptions }}</h2>
              <template v-for="(item, i) in hubDetailsData.descriptions">
                <v-row :key="i" wrap no-gutters class="pt-2">
                  <v-col cols="1" class="">
                    <p>{{ $lang.labels[item.language] }}:</p>
                  </v-col>
                  <v-col cols="11" class="">
                    <p class="color-primary">{{ item.description }}</p>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </template>
              <v-row v-if="showAddReview" wrap no-gutters class="pt-1">
                <h2 style="margin-top: 8px">{{ $lang.labels.submitReview }}</h2>
                <v-btn
                  color="accent"
                  class="ml-2 color-accent-text mt-1"
                  @click="showAddReview = !showAddReview"
                >
                  {{ $lang.actions.showReviews }}
                </v-btn>
              </v-row>
              <v-row v-if="showAddReview" wrap no-gutters class="pt-2">
                <v-col cols="4" class="">
                  <v-text-field
                    v-model="review.title"
                    outlined
                    dense
                    :label="$lang.labels.title"
                    required
                    :rules="[v => !!v || $lang.labels.required]"
                    class="required-asterisk"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="">
                  <v-textarea
                    v-model="review.body"
                    outlined
                    dense
                    rows="3"
                    auto-grow
                    required
                    :rules="[v => !!v || $lang.labels.required]"
                    :label="$lang.labels.body"
                    class="required-asterisk"
                  ></v-textarea>
                </v-col>
                <v-col cols="8" class="mb-1">
                  {{ $lang.labels.rating }}: &nbsp;
                  <template v-for="star in 5">
                    <v-icon
                      :key="star"
                      class="cursorPointer"
                      size="32"
                      :color="gold[star] ? 'primary' : ''"
                      @mouseenter="starGold(star)"
                      @click="review.rating = star - 1; starGold(star)"
                    >mdi-star
                    </v-icon>
                  </template>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-btn
                    :disabled="!review.title || !review.body"
                    color="success"
                    class="button-default-width ml-2"
                    @click="submitReview()"
                  >
                    {{ $lang.actions.submit }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="finishInit && !showAddReview" dense no-gutters>
                <h2 style="margin-top: 8px">{{ $lang.labels.reviews }}</h2>
                <v-btn
                  color="accent"
                  class="button-default-width ml-2 color-accent-text mt-1"
                  @click="showAddReview = !showAddReview"
                >
                  {{ $lang.actions.addReview }}
                </v-btn>
                <template v-for="(reviewInner, index) in hubReviews">
                  <v-col :key="index" cols="12">
                    <v-card flat class="pa-2">
                      <v-row wrap no-gutters>
                        <v-col cols="12">
                          <p>
                            <v-icon class="mr-1">mdi-account</v-icon>
                            {{ formatUser(reviewInner.created_by) }}
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <h3 class="pa-0 ma-0">
                            <template v-for="star in 5">
                              <v-icon
                                :key="star + 500"
                                size="22"
                                :color="reviewInner.gold[star] ? 'primary' : ''"
                              >mdi-star
                              </v-icon>
                            </template>
                            {{ reviewInner.title }}
                          </h3>
                        </v-col>
                        <v-col cols="12" class="mt-2">
                          <p>{{ reviewInner.body }}</p>
                        </v-col>
                        <v-col cols="12" class="pt-2">
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </template>
                <v-col cols="12">
                  <v-pagination
                    v-model="page"
                    :length="pageLength"
                  />

                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item
            :key="1"
            class="fill-height"
          >
            <v-card flat class="pt-1 fill-height">
              <v-row wrap no-gutters class="pb-4">
                <v-col cols="12" class="pb-4">
                  <v-expansion-panels flat color="surface">
                    <template v-for="(item, i) in validationResult">
                      <v-expansion-panel
                        :key="i"
                      >
                        <v-expansion-panel-header>
                          {{ $lang.status[item.type] }}: <span
                            :class="(item.missedRequiredResources && item.missedRequiredResources.length > 0) || !item.hasPermission || !item.isExist ? 'color-error' : 'color-primary'"
                          >&nbsp;{{
                            item.name
                          }}</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row wrap no-gutters class="pb-2">
                            <v-col cols="6">
                              <h4>&nbsp;{{ $lang.labels.permission }}: <span
                                :class="item.hasPermission ? 'color-primary' : 'color-error'"
                              >&nbsp;{{
                                item.hasPermission ? $lang.labels.yes : $lang.labels.no
                              }}</span></h4>
                            </v-col>
                            <v-col cols="6">
                              <h4>&nbsp;{{ $lang.labels.exist }}: <span class="color-primary">&nbsp;{{
                                item.isExist ? $lang.labels.yes : $lang.labels.no
                              }}</span></h4>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="item.missedRequiredResources && item.missedRequiredResources.length > 0"
                            no-gutters
                            class="pb-2"
                          >
                            <v-divider></v-divider>
                          </v-row>
                          <div
                            v-if="item.missedRequiredResources && item.missedRequiredResources.length > 0"
                            class="pb-2"
                            style="width: 100%"
                          >
                            <h3 class="color-error pb-2">&nbsp;{{ $lang.labels.missingParams }}</h3>
                            <template v-for="(missing, index) in item.missedRequiredResources">
                              <v-row :key="index" no-gutters class="pb-2">
                                <v-col cols="12">
                                  <h4>&nbsp;{{ $lang.status[missing.type] }}: <span
                                    class="color-primary"
                                  >&nbsp;{{ missing.name }}</span></h4>
                                </v-col>
                              </v-row>
                            </template>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </template>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-card-actions v-if="hubDetailsData && hubDetailsData.is_contains_plugin" class="justify-space-between">
      <v-row dense no-gutters class="px-0">
        <v-col cols="12">
          <v-alert color="warning" :data-cy="err" style="color: black">{{ $lang.hints.pluginPresent }}</v-alert>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-actions v-if="hubDetailsData" class="justify-space-between">
      <v-btn
        color="secondary"
        class="button-default-width"
        :to="{ name: 'hub', params: { lang: $lang.current_lang } }"
      >
        <v-icon
          right
          dark
          class="ma-0"
        >
          mdi-arrow-left
        </v-icon>
        <span class="ml-1">{{ $lang.actions.back }}</span>
      </v-btn>
      <div class="d-inline-flex align-center">
        <v-progress-circular v-if="loading" class="mr-2" indeterminate color="primary"></v-progress-circular>
        <p style="padding: 0; margin: 0">{{ $lang.labels.credential }}: <span class="color-primary">{{
          credential.name
        }}</span></p>
        <v-btn
          :disabled="!isEdit"
          color="success"
          class="button-default-width ml-2"
          @click="installModuleFunction()"
        >
          {{ $lang.actions.install }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  hubModuleDownloadUsingGET as installModule,
  hubReviewCreateUsingPOST as addReview,
  hubReviewsSearchUsingGET as fetchReviews,
  hubGetModuleUsingGET as fetchModule, getProcessCredentialByIdUsingGET as getCredential
} from '@/utils/api'
import { fetchUserData } from '@/utils/helpers'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      showAddReview: false,
      err: '',
      success: '',
      tab: 0,
      validationShow: false,
      isEdit: false,
      loading: true,
      lock: false,
      resourcesTypes: ['GLOBAL_TRANSLATION', 'MODULE', 'PROCESS', 'PROCESS_CREDENTIAL', 'SETTING', 'TEXT_TEMPLATE', 'TRIGGER_CRON', 'TRIGGER_REST', 'VALIDATION_RULE'],
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      isSuperUser: false,
      validationResult: null,
      languages: ['bg', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk'],
      review: {
        body: '',
        rating: 2,
        title: ''
      },
      labels: ['1', '2', '3', '4', '5'],
      tableKey: 0,
      hubReviews: [],
      page: 1,
      pageLength: 1,
      finishInit: false,
      gold: [true, true, false, false, false, false],
      goldSet: null,
      hubDetailsData: null,
      credential: null
    }
  },
  computed: {},
  watch: {
    page: {
      handler() {
        if (this.hubDetailsData && this.$route.query.credentialId)
          this.fetchHubReviews()
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'hubEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
    }

    if (this.$route.params.id && this.$route.query.credentialId) {
      getCredential({ id: this.$route.query.credentialId })
        .then((res) => {
          this.credential = res.data.data
          fetchModule({ moduleId: this.$route.params.id, credentialId: this.$route.query.credentialId }).then((res) => {
            if (res?.data?.data) {
              this.hubDetailsData = res.data.data
              setTimeout(() => {
                this.loading = false
                this.fetchHubReviews()
              }, 1)
            } else {
              this.addSnackbar({
                message: res?.response?.data?.statusText,
                timeout: 5000,
                color: 'error'
              })
            }
          })
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    starGold(index) {
      this.gold = this.gold.map((x, i) => {
        let item = x

        item = this.goldSet ? i <= this.goldSet : i <= index

        return item
      })
    },
    async init() {
      const allSavedUsers = localStorage.allUsers ? JSON.parse(localStorage.allUsers).map((x) => x.id) : []
      const itemsUsers = this.hubReviews.map((x) => x.created_by)

      const newUsers = allSavedUsers.length > 0 ? itemsUsers.filter((y) => !allSavedUsers.includes(y)) : itemsUsers

      const newUsersUnique = [...new Set(newUsers)]

      if (newUsersUnique.length === 0) {
        this.finishInit = true

        return
      }

      const fetchedUsers = newUsersUnique.map(async (id) => {
        return this.fetchUser(id)
      })

      await Promise.all(fetchedUsers)
        .then((result) => {
          const allSavedUsersFull = localStorage.allUsers ? JSON.parse(localStorage.allUsers) : []

          localStorage.allUsers = JSON.stringify([...allSavedUsersFull, ...result])

          this.finishInit = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    formatUser(id) {
      const allSavedUsers = localStorage.allUsers ? JSON.parse(localStorage.allUsers) : []
      const findById = allSavedUsers.find((user) => user.id === id)

      if (findById) return findById.name

      return ''
    },
    async fetchUser(id) {
      return new Promise((resolve, reject) => {
        fetchUserData(id)
          .then((res) => {
            resolve(res)
          })
          .catch(() => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    fetchHubReviews() {
      this.finishInit = false
      this.hubReviews = []

      const obj = {
        page: this.page,
        size: 5,
        remoteModuleId: this.$route.params.id,
        credentialId: this.$route.query.credentialId
      }

      const tempArr = [false, false, false, false, false, false]

      fetchReviews(obj)
        .then((res) => {
          this.hubReviews = res.data.data.items.map((x) => {
            x.gold = tempArr.map((y, i) => {
              let item = y

              item = x.rating ? i <= x.rating : i <= i

              return item
            })

            return x
          })
          this.pageLength = res.data.data.meta.totalPages
          if (this.hubReviews && this.hubReviews.length > 0) {
            this.init()
          } else {
            this.finishInit = true
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    submitReview() {
      this.loading = true
      this.lock = true

      const request = {
        ...this.review,
        rating: Number(this.review.rating) + 1,
        remoteModuleId: this.$route.params.id,
        credentialId: this.$route.query.credentialId
      }

      addReview({ body: request })
        .then((res) => {
          if (res?.response?.data?.status === 'FAILED') {
            this.addSnackbar({
              message: res?.response?.data?.statusText,
              timeout: 5000,
              color: 'error'
            })  
            this.loading = false
            this.lock = false

            return
          }

          this.loading = false
          this.lock = false
          this.showAddReview = false
          this.addSnackbar({
            message: this.$lang.success.reviewSubmit,
            timeout: 5000,
            color: 'success'
          })  
          this.fetchHubReviews()
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
          this.lock = false
        })
    },
    async installModuleFunction() {

      if (!this.credential || !this.hubDetailsData) return

      this.loading = true
      this.lock = true

      this.tab = 0
      this.validationShow = false
      this.validationResult = null

      try {

        const res = await installModule({ credentialId: this.$route.query.credentialId, remoteModuleId: this.$route.params.id })

        if (res && res.status !== 200) {
          this.addSnackbar({
            message: this.$lang.errors.moduleInstall,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
          this.lock = false

          return
        }

        this.loading = false
        this.lock = false

        if (res.data) {
          this.validationResult = res.data.data
          this.validationShow = true
          this.tab = 1
        }
        this.addSnackbar({
          message: this.$lang.success.moduleInstalled,
          timeout: 5000,
          color: 'success'
        })

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })        
        this.loading = false
        this.lock = false
      }
    }
  }
}
</script>
<style lang="scss">
.bg-color {
  background-color: var(--v-surface-base) !important;
}

</style>
